import * as React from "react"
import {Link} from "gatsby"
import Main from "../components/Layout/Main";
import Breadcrumb from "../components/Layout/Breadcrumb";
import Seo from "../components/Seo";

const NotFoundPage = () => {
    return (
        <Main>
            <Seo title="Página não encontrada" description="Opss, página não encontrada."/>
            <Breadcrumb title="Página não encontrada"/>
            <div className="container mx-auto px-4 mb-9">
                <p className="my-44">
                    <span className="text-darkpurple block font-bold text-6xl pb-5">Opss!!</span>
                    A página não foi encontrada, por favor navegue pelo menu para encontrar o que deseja.
                    <br/>
                    <br/>
                    <Link to="/" className="text-purple">Ir para página inicial</Link>.
                </p>
            </div>

        </Main>
    )
}

export default NotFoundPage
